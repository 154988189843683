<template>
  <div class="back-header-nb">
    <!--  header部分-->
    <div class="back-header">
      <h3>Plantorganelle hunter</h3>
      <div class="back-header-item">
        <p :class="unfoldIcon" @click="CloseDown"></p>
        <p class="el-icon-refresh-right back-header-p" @click="headerReload"></p>
        <el-breadcrumb separator="/" class="back-header-p">
          <el-breadcrumb-item v-for="(str,index) in bread" :key="index">{{str}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  导航部分-->
    <div class="back-header-nav">
      <el-row :gutter="10">
        <el-col :lg="Wh.lg" :md="Wh.md" :sm=Wh.sm :xl="Wh.xl">
          <el-menu
              style="z-index: 10"
              default-active="/identification"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              router
              @close="handleClose"
              text-color="#ffffff"
              background-color="#000000"
              :collapse-transition="true"
              :collapse="isCollapse">
            <el-menu-item class="back-header-menu" @click="ElMenu('IdentificationRecord')" index="/identification">
              <i class="el-icon-document"></i>
              <span slot="title">Identification record</span>
            </el-menu-item>
            <el-menu-item index="/DataRequest" @click="ElMenu('DataRequest')">
              <i class="el-icon-share"></i>
              <span slot="title">Data request</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col class="back-header-newFlag" :lg="Th.lg" :md="Th.md" :sm="Th.sm" :xl="Th.xl">
          <div class="back-header-tabs">
            <el-col :span="1"
                    style="
                   width: 200px;
                   background-color: white;
                   position: relative;
"
            >
              <div style="
             height: 40px;
             line-height: 40px;
             width:200px;
             left:-200px;
             color: #c2c2c2;
             font-size: 18px;
             cursor: pointer;
             text-align: right;
             position: absolute;
             background-color: white;
             ">
                <a style="text-decoration: none;color: #c2c2c2;" href="javascript:history.back()"><span style="margin-right:-25px;">＜</span></a>
              </div>

            </el-col>
            <el-col :span="23" style="margin-left:40px">
              <el-tabs v-model="editableTabsValue" type="card"  @edit="handleTabsEdit">
                <el-tab-pane
                    :key="index"
                    v-for="(item, index) in editableTabs"
                    :label="item.title"
                    :name="item.name"
                    :disabled="item.disabled"
                    class="back-header-pane"
                    :closable="item.closable"
                >
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </div>
          <el-col class="back-header-col" :span="24">
            <router-view v-if="isRouterAlive"></router-view>
          </el-col>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "index",
  provide() {
    return {
      reload: this.reload,
    }
  },
  data(){
    return{
      isRouterAlive: true,
      //展开关闭控制
      isCollapse:false,
      //展开关闭icon变换
      unfoldIcon:'el-icon-s-fold',
      editableTabsValue: '2',
      bread:this.$store.state.BreadCrumbs,
      editableTabs:this.$store.state.ediTable,
      tabIndex: 2,
      //控制宽度
      Wh:{lg:4,md:5,sm:6,xl:3},
      Th:{lg:20,md:19,sm:18,xl:21}
    }
  },
  created() {
    let atu=window.sessionStorage.getItem('assign__token')
    if(!atu){
      this.$message.warning('请登录！！！');
      this.$router.push('/login')
    }
  },
  methods:{
    //面包靴
    ElMenu(value){
      if (this.editableTabs.length>1){
        this.editableTabs.pop()
        this.bread.pop()
      }
      let data={
        title:value,
        name:'1',
        closable:false,
        disabled:true
      }
      this.$store.state.BreadCrumbs[0]=value
      this.editableTabs[0]=data
    },
    //刷新表格
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    //
    CloseDown(){
      if(this.isCollapse===false&&this.unfoldIcon==='el-icon-s-fold'){
        this.isCollapse=true
        this.unfoldIcon='el-icon-s-unfold'
        this.Wh.lg=1
        this.Wh.md=1
        this.Wh.sm=1
        this.Wh.xl=1
        this.Th.lg=23
        this.Th.md=23
        this.Th.sm=23
        this.Th.xl=23
      }else {
        this.isCollapse=false
        this.unfoldIcon='el-icon-s-fold'
        this.Wh.lg=4
        this.Wh.md=5
        this.Wh.sm=6
        this.Wh.xl=3
        this.Th.lg=20
        this.Th.md=19
        this.Th.sm=18
        this.Th.xl=21
      }
    },
    headerReload(){
      this.reload()
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleTabsEdit(targetName, action) {
      if (action === 'remove') {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.editableTabsValue = activeName;
        // if(this.$store.state.BreadCrumbs.length>2 && targetName==2){
        //   for (let i=1;i<=2;i++){
        //     this.editableTabs.splice(i,1)
        //     this.bread.splice(i,1)
        //     if (this.bread.length===2){
        //       i--;
        //     }
        //   }
        //   this.$router.push({
        //     path:'/identification'
        //   })
        // }else if (this.editableTabs.length>2 && targetName==3){
        //   this.$store.state.BreadCrumbs.pop()
        //   this.$store.state.ediTable.pop()
        //   this.$router.push({
        //     path:'/identification'
        //   })
        // }
          this.$store.state.BreadCrumbs.pop()
          this.$store.state.ediTable.pop()
          this.$router.push({
          path:'/identification'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.back-header-nb{
  min-width: 1200px;
  min-height: 600px;
  overflow: hidden;
  width:100%;
  height: 100vh;
  background-color: #f0f2f5;
  .back-header{
    height:50px;
    width: 100%;
    min-width: 1200px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index:20;
    background: white;
    h3{
      margin-left: 20px;
    }
    .back-header-item{
      display: flex;
      align-items: center;
      margin-left: 80px;
      .back-header-p{
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
  .back-header-nav{
    //margin-top:-8px;
    //margin-left: -10px;
    .el-menu-vertical-demo{
      height:100vh;
    }
    .back-header-menu{
      margin-top:70px;
    }
    .back-header-tabs{
      height: 40px;
      border-top: #f3f3f3 1px solid;
      margin-top: 50px;
      background-color: white;
      ::v-deep .el-tabs__nav{
        border: 0;
      }
      ::v-deep .el-tabs__nav .el-tabs__item{
        border: 0;
      }
      ::v-deep .el-tabs__nav .el-tabs__item:hover{
        background-color:#d9e3d5;
      }
    }
  }
}
</style>
